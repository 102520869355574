"use client";

import { Pagination as MUIPagination, PaginationItem } from "@mui/material";
import Link from "next/link";
import { usePathname, useSearchParams } from "next/navigation";

const PaginationNum = ({ pageCount }: { pageCount: string }) => {
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const currentPage = Number(searchParams.get("page")) || 1;

  const createPageURL = (pageNumber: number | string) => {
    const params = new URLSearchParams(searchParams);
    params.set("page", pageNumber.toString());
    return `${pathname}?${params.toString()}`;
  };

  return (
    <MUIPagination
      sx={{
        marginTop: 4,
        "& .MuiPaginationItem-root": {
          color: "#EFF6E0",
        },
      }}
      color="primary"
      page={currentPage}
      count={parseInt(pageCount)}
      boundaryCount={2} // Number of always visible pages at the beginning and end
      siblingCount={2} // Number of always visible pages before and after the current page
      renderItem={(item) => {
        const isFirstPage = currentPage === 1;
        const isLastPage = currentPage === parseInt(pageCount);

        if (
          (item.type === "previous" && isFirstPage) ||
          (item.type === "next" && isLastPage)
        ) {
          // Disable the back/forward button
          return <PaginationItem {...item} disabled />;
        }

        return (
          <Link
            style={{ textDecoration: "none" }}
            href={createPageURL(item.page ?? 1)}
          >
            <PaginationItem {...item} />
          </Link>
        );
      }}
    />
  );
};

export default PaginationNum;
